<template>
  <v-container
    id="regular-tables-view"
    fluid
    tag="section"
  >

    <material-card
      icon="mdi-clipboard-text"
      icon-small
      title="Користувачі"
      color="accent"
      class="mb-16"
    >
      <v-row class="mx-2 mb-2">
        <v-col cols="12" md="4">
          <v-text-field
            v-model="name"
            label="ПIБ"
            clearable
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            v-model="login"
            label="Логiн"
            clearable
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            v-model="email"
            label="Email"
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-simple-table>
        <thead>
          <tr>
            <th class="primary--text">
              ID
            </th>
            <th class="primary--text">
              ПIБ
            </th>
            <th class="primary--text">
              Логiн
            </th>
            <th class="primary--text">
              Email
            </th>
            <th v-if="isAdmin" width="50"></th>
          </tr>
        </thead>

        <tbody v-if="users.length">
          <tr v-for="(user, index) in users" :key="`user-${index}`">
            <td>{{ user.id }}</td>
            <td>{{ user.full_name }}</td>
            <td>{{ user.login }}</td>
            <td>{{ user.email }}</td>
            <td v-if="isAdmin">
              <v-icon
                color="green"
                @click="toUserProfil(user.id)"
              >
                mdi-pencil
              </v-icon>
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <div v-if="users.length && totalCount > pageSize" class="text-center py-4">
        <v-pagination
          v-model="currentPage"
          :total-visible="9"
          :length="pageLength"
        ></v-pagination>
      </div>
    </material-card>

    <v-btn
      class="add-user__button"
      fab
      dark
      color="primary"
      to="/users/add/"
    >
      <v-icon dark>
        mdi-plus
      </v-icon>
    </v-btn>

  </v-container>
</template>

<script>
import {
    HTTP
} from '../http-common';
import { mapGetters } from 'vuex'

  export default {
    name: 'RegularTablesView',
    data() {
      return {
        users: [],
        name: null,
        login: null,
        email: null,
        currentPage: 1,
        pageSize: 5,
        totalCount: null
      }
    },
    computed: {
      ...mapGetters(['isAdmin']),
      pageLength() {
        if (this.totalCount) {
          return Math.round(this.totalCount / this.pageSize)
        }
      }
    },
    methods: {
      getUsers() {
        HTTP.post('/get-users', {
          full_name: this.name ? this.name : null,
          login: this.login ? this.login : null,
          email: this.email ? this.email : null,
          limit: this.pageSize,
          page: this.currentPage
        })
          .then(({ data }) => {
            this.users = data.content
            this.totalCount = data.content.total_count || null
          })
          .catch((error) => {
            console.error(error);
          })
      },
      toUserProfil(id) {
        this.$router.push(`/users/${id}`);
      }
    },
    watch: {
      name() {
        this.getUsers();
      },
      login() {
        this.getUsers();
      },
      email() {
        this.getUsers();
      },
    },
    mounted() {
      this.getUsers();
    }
  }

</script>

<style>
.add-user__button {
  position: fixed;
  bottom: 20px;
  right: 20px;
}
</style>
